export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/history-share-map/history-share-map/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/forget',
    name: '/forget',
    component: () => import('/home/runner/work/history-share-map/history-share-map/src/pages/forget.vue'),
    /* no children */
  },
  {
    path: '/inviteCode',
    name: '/inviteCode',
    component: () => import('/home/runner/work/history-share-map/history-share-map/src/pages/inviteCode.vue'),
    /* no children */
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/home/runner/work/history-share-map/history-share-map/src/pages/login.vue'),
    /* no children */
  },
  {
    path: '/map',
    name: '/map',
    component: () => import('/home/runner/work/history-share-map/history-share-map/src/pages/map.vue'),
    /* no children */
  },
  {
    path: '/register',
    name: '/register',
    component: () => import('/home/runner/work/history-share-map/history-share-map/src/pages/register.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

