<script lang="ts" setup></script>

<template>
  <AConfigProvider size="mini">
    <RouterView />
  </AConfigProvider>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
